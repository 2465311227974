@import "~snazzy-info-window/dist/snazzy-info-window.css";

// Import library functions for theme creation.
@import '../node_modules/@angular/material/theming';

// Include non-theme styles for core.
@include mat-core();

// Define your application's custom theme.
$primary: mat-palette($mat-red, 600);
$accent: mat-palette($mat-red, 900);
$warn: mat-palette($mat-yellow, 700);
$theme: mat-light-theme($primary, $accent, $warn);
$hover: mat-color($mat-gray, 100);
$selection: mat-color($mat-gray, 200);

// Include theme styles for Angular Material components.
@include angular-material-theme($theme);

/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

mat-toolbar {
  .mat-mdc-icon-button:first-child {
    margin-right: 8px;
  }

  .mat-mdc-icon-button:last-child {
    margin-left: 8px;
  }

  .mat-mdc-icon-button:not(:first-child):not(:last-child) {
    margin-left: 8px;
    margin-right: 8px;
  }
}

.sub-toolbar {
  background-color: mat-color($mat-red, 900);
  color: white;
  height: 56px !important;
  z-index: 400;
}

.vertical-actions-container {
  padding: 0 8px;
  background-color: mat-color($mat-gray, 100);

  .mat-mdc-icon-button:first-child {
    margin-top: 16px;
    margin-bottom: 8px;
  }

  .mat-mdc-icon-button:last-child {
    margin-top: 8px;
    margin-bottom: 16px;
  }

  .mat-mdc-icon-button:not(:first-child):not(:last-child) {
    margin-bottom: 8px;
    margin-top: 8px;
  }
}

.no-padding-dialog .mat-mdc-dialog-container {
  padding: 0;
}

.mat-mdc-header-row {
  background-color: mat-color($mat-gray, 100);
}

.mat-mdc-tooltip {
  background-color: mat-color($mat-gray, 900);
  font-size: 1rem;
}

.h100 {
  height: 100%;
}

.w100 {
  width: 100%;
}

.flex1 {
  flex: 1;
}

.flex-column-fill {
  display: flex;
  flex-direction: column;
  flex: 1;
}
